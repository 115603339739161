import { ProtectedRoute } from '@cksoftware/react-base';
import { createBrowserRouter } from 'react-router-dom';
import { BoxLayout } from 'src/components/Layout/BoxLayout';
import { MainLayout } from 'src/components/Layout/MainLayout';
import { AllAccessRoles, ApplicationRoles } from 'src/constants/appRoles';
import { Home } from 'src/features/misc/Home';
import { PageNotFound } from 'src/features/misc/PageNotFound';
import { AuthorizedRoutes } from './AuthorizedRoutes';
import { UnauthorizedRoutes } from './UnauthorizedRoutes';
import { RedirectToLogin } from './RedirectToLogin';
export const FrontierRoutes = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        Component: Home
      }
    ]
  },
  {
    path: '/',
    element: <BoxLayout />,
    children: UnauthorizedRoutes
  },
  {
    path: '/',
    element: (
      <ProtectedRoute
        accessDeniedOverride={<RedirectToLogin />}
        allowedRoles={[
          ...AllAccessRoles,
          ApplicationRoles.reservations,
          ApplicationRoles.obtUser,
          ApplicationRoles.obtSuperUser,
          ApplicationRoles.obtAuthBooker
        ]}>
        <MainLayout />
      </ProtectedRoute>
    ),
    children: AuthorizedRoutes
  },

  //404
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  }
]);
